// Font Awesome configuration
$fa-font-path:        "/v/fonts";

// Disable the bootstrap grid
$enable-grid-classes: false;

$primary: #83014c;
//$primary: #69626D;
//$secondary: #360f4f; // standard EHRI purple
//$secondary: #442f51; // nice dark blue/purple
//$secondary: #38333c; // Dark gray
$secondary: #352f4c; // More dark blue

////$secondary: #69626D;
//$secondary: #034153;

$headings-font-family: 'Barlow Semi Condensed', sans-serif;
$font-family-base: 'Roboto', sans-serif;
//$headings-color: $secondary;

$text-darkened: #5e5e5e;

// Colors FIXME - make these more coherent
$blue: #005fc5;
$red: #e3092f;
$green: #3c763d;

$info: #407ab5;

  // Footer and category badges etc
$gray-200: #ecedee;

// Make light a bit darker
$light: $gray-200;

$ehri-gray: $gray-200;
$ehri-border-gray: #cecfcf;
$divider-color: rgba(131, 1, 76, 0.13);

$pagination-active-bg: $secondary;

$text-muted: #858c8c;

$font-size-xs: .75rem; 		// 12px
$font-size-sm: 0.875rem; 	// 14px
$font-size-base: 1.0625rem; // 17px
$font-size-lg: 1.25rem;		// 20px

// Headings
$h1-font-size: 2.625rem;
$h2-font-size: 1.375rem;
$h3-font-size: 1.375rem;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$border-color: $ehri-border-gray;
$border-width: 1px;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
) !default;

// golden ratio margins...
$margin-xxs: 0.309rem;
$margin-xs: 0.5rem;
$margin-sm: 0.809rem;	// 0.75rem
$margin-md: 1.309rem;	// 1.5rem
$margin-lg: 2.118rem;	// 3rem
$margin-xl: 3.4270510rem; // 6rem

// borders
$border-radius:               .05rem !default;
$border-radius-lg:            .15rem !default;
$border-radius-sm:            .05rem !default;
$border-radius-xs:            .025rem !default;


// Grid spacing
$grid-gutter-width: $margin-lg;

$list-margin-bottom: $margin-md;

// Buttons...
$btn-font-size: $font-size-sm;
//$btn-font-weight: bold;

$btn-padding-x: $margin-sm;
$btn-padding-y: $margin-xxs;

// Make modal headers a bit smaller
$modal-header-padding-y: $margin-xs;

// Item-type colours
$documentary-unit-color: $primary;
$repository-color: #034153;
$historical-agent-color: #517c00;
$country-color: #864300;
$concept-color: #283e00;
