// NB: font-awesome iconfont is managed separately since it is delivered as SCSS files

/* barlow-semi-condensed-600 - latin-ext_latin */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('/v/fonts/barlow-semi-condensed-v13-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/v/fonts/barlow-semi-condensed-v13-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/v/fonts/barlow-semi-condensed-v13-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/v/fonts/barlow-semi-condensed-v13-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('/v/fonts/barlow-semi-condensed-v13-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/v/fonts/barlow-semi-condensed-v13-latin-ext_latin-600.svg#BarlowSemiCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/v/fonts/roboto-v29-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/v/fonts/roboto-v29-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/v/fonts/roboto-v29-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/v/fonts/roboto-v29-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/v/fonts/roboto-v29-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/v/fonts/roboto-v29-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* material design icons, installed via NPM */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/v/fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('/v/fonts/MaterialIcons-Regular.woff2') format('woff2'),
  url('/v/fonts/MaterialIcons-Regular.woff') format('woff'),
  url('/v/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

