@import "mixins";

/**
  Bootstrap 4-based layout and responsiveness.
 */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.main-content {
  @include make-container();
  @include make-container-max-widths();
}

// Main page right sidebar layouts
.double {
  @include media-breakpoint-up(lg) {

    @include make-row();

    > aside {
      // Place sidebar on the right...
      // Margin prevents a re-layout due to sidebar loading first
      order: 1;
      margin-left: auto;
      @include make-col-ready();
      @include make-col(3);
    }

    > article {
      order: 0;
      @include make-col-ready();
      @include make-col(9);
    }
  }
}

.search-results {
  @include media-breakpoint-up(lg) {

    @include make-row();

    > aside {
      // Place sidebar on the right...
      // Margin prevents a re-layout due to sidebar loading first
      order: 1;
      margin-left: auto;

      @include make-col-ready();
      @include make-col(3);
    }

    > section {
      order: 0;
      @include make-col-ready();
      @include make-col(9);
    }
  }
}

// Description text fields
@include media-breakpoint-up(lg) {
  .item-text-field {
    @include make-row();

    > .item-text-field-header {
      @include make-col-ready();
      @include make-col(3);
    }

    > .item-text-field-value {
      @include make-col-ready();
      @include make-col(9);
    }
  }
}

// Breadcrumbs
.info-bar {
  > ul,
  > ol {
    @extend .breadcrumb;
    @include make-container();
    @include make-container-max-widths();
    > li {
      @extend .breadcrumb-item;
    }
  }
}

// Homepage
#welcome-container {
  @include make-container();
  @include make-container-max-widths();
}

// Navbar
ul.dropdown-menu {
  > li {
    @extend .dropdown-item;
  }
}

// Sidebar
.item-sidebar-actions, .export-formats {
  @include list-unstyled();
}

// Footer
#portal-footer {
  margin-top: auto;

  #footer-content {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Login / Register pages
@include media-breakpoint-up(md) {
  .login-page {
    @include make-row();

    .login-or-signup {
      @include make-col-ready();
      @include make-col(6);
      @include make-col-offset(3);
    }
  }
}

// Error page layouts
body.error .main-content .error-block {
  @include media-breakpoint-up(md) {
    max-width: map-get($container-max-widths, md);
    margin-left: auto;
    margin-right: auto;
  }
}

// Activity stream / Timeline
#activity-stream {
  @include list-unstyled();
}

.timeline-event {
  @extend .media;

  .timeline-body {
    @extend .media-body;
  }
}

// Search form and results
#search-helper {
  @extend .collapse;
}

.search-result-list {
  @include list-unstyled();
}

.search-item-actions {
  float: right;
  display: flex;
  flex-direction: column;
}

.search-item-details {
  @include list-unstyled();
  @include list-inline();
  li {
    padding-right: $margin-xxs;
  }
  li:last-child {
    padding-right: 0;
  }
}

ul.pagination {
  li {
    @extend .page-item;

    a {
      @extend .page-link;
    }
  }
}

.facet-classes {

  @include list-unstyled();

  // Show facets in wide views
  &.collapse {
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.search-result-errata {
  @include clearfix();

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

// Users pages and profile
.user-list {
  @include list-unstyled();
}

.user-info {
  @extend .media;

  .user-info-details {
    @extend .media-body;

    > ul {
      @include list-unstyled();
    }
  }

  .follow-controls {
    float: right;
  }
}

// Description pages
.description-holder.inactive {
  display: none;
}

// Description lists (dl) elements... make
// responsive for wide sizes
@include media-breakpoint-up(md) {
  .description dl {
    @include make-row();

    dt {
      @include make-col-ready();
      @include make-col(3);
    }
    dd {
      @include make-col-ready();
      @include make-col(9);
    }
  }
}

// Form controls using `control-label` and
// standard form controls
@include media-breakpoint-up(lg) {
  .form-group {
    @include make-row();

    > .control-label {
      @include make-col-ready();
      @include make-col(3);
    }

    > .control-elements {
      @include make-col-ready();
      @include make-col(9);
    }
  }
}

.inline-element {
  > .form-group {
    @include make-row();

    > .inline-control {
      @include make-col-ready();
      @include make-col(11);
    }

    > .inline-remove {
      @include make-col(1);
    }
  }
}

// `control-elements`
.control-elements {
  // this is set so hints labels (such as mandatory/desirable) can
  // be positions absolutely relative to the control input
  position: relative;
}
